<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<script>
  import Layout from "../../layouts/main";
  import CheckHeader from "@/components/check-header";
  import changeExam from '@/components/change-exam.vue'
  import {
    getNowExam,
  } from "@/api/admin/exam/examRecord.js"
  import {getCheckPowerPage} from "../../../api/admin/exam/checkPower";
  import YzSearch from "@/components/form/yzSearch.vue";
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      YzSearch,
      changeExam
    },
    data() {
      return {
        title: "审核工作量  / ",
        title2:"内江师范学院2021年12月考核招聘艺体类工作人员招聘考试",
        subTitle: "切换报名",
        items: [{
            text: "用户首页"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        defaultvalue: 1,
        currentpage: 1,
        ksbmbh:"",
        ksmc:"",
        shfl:"",
        tableList: [],
        pageData: {},
        search: [],
      };
    },
    methods: {
      //  获取审核人列表
      getList() {
        getCheckPowerPage(this.pageData).then((res) => {
          if (res.status) {
            this.tableList = res.data;
            this.pageData.total = res.total;
            this.pageData.pageNum = res.pageNum;
            this.pageData.pageSize= res.pageSize;
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList();
      },
      //获取切换的考试
      getLastExam() {
        getNowExam().then(res => {
          if (res.status) {
            this.pageData.shfl = res.data.zgjgshshms
            this.ksbmbh = res.data.ksbmbh
            this.ksmc = res.data.ksmc
            this.getList()
          }
        })
      },
    },
    mounted() {
      this.getLastExam()
      this.search = [
        {
          title: "姓名/账号",
          bound: "keyword",
          type: "input",
        },
      ];
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body ">
            <div class="pb-3 border-dash  check-table-top">
              <div class="flexList" style="flex: 1">
                <yz-search
                    :data="search"
                    v-model="pageData"
                    @seachList="searchClick"
                ></yz-search>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th width="4%">序号</th>
                    <th>机构</th>
                    <th>单位</th>
                    <th style="width:8%">账号</th>
                    <th style="width:5%">姓名</th>
                    <th style="width:6%">审核任务 </th>
                    <th style="width:6%">待审核 </th>
                    <th style="width:6%">已审核 </th>
                    <th style="width:20%">进度 </th>

                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, i) in tableList"  :key="i">
                    <td scope="row">{{ (pageData.pageNum - 1) * pageData.pageSize +  i + 1 }}</td>
                    <td> {{obj.jgmc}}</td>
                    <td> {{obj.dwmc}}</td>
                    <td>{{obj.yhzh}}</td>
                    <td>{{obj.yhxm}}</td>
                    <td>{{obj.shrws}}</td>
                    <td>{{obj.wsh}}</td>
                    <td>{{obj.ysh}}</td>
                    <td>
                      <div class="progress  border-25 mt-1" style="height: 18px;">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="25"
                          class="progress-bar bg-info border-25" :style="{ width: (obj.ysh/obj.shrws) * 100 + '%' }">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{obj.jd}}</font>
                          </font>
                        </div>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination
                  @size-change="handleSizeChange"
                  background
                  @current-change="handleCurrentChange"
                  :current-page.sync="pageData.pageNum"
                  :page-size="pageData.pageSize"
                  layout="total, sizes,jumper, prev, pager, next"
                  :total="pageData.total"
              >
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <changeExam></changeExam>
    <!-- 弹窗结束 -->
  </Layout>
</template>
